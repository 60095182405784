<template>
  <div class="form-section" :class="$store.state['App-UiColorSchema-api'].currentSchemaClasses">
    <div class="title " style="color: #67C23A" @click="visibility = !visibility">1 - Business
      <i :class="{'el-icon-arrow-down': visibility, 'el-icon-arrow-right' : (!visibility) == true}"></i>
    </div>

    <div v-show="visibility">
      <el-row>
        <el-col :class="$langConfig.cssClass" style="width: 170px;margin-right: 10px;">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="18"
              :label_span="6"
              valueProperty="value"
              :options="$store.state['App-User-api'].usersByProfiles.ADMIN"
              :translateLabel='false'
              :translateLabels="false"
              field_mark="required"
              label="User"
              name="account_person_in_charge_user_id"
              placeholder="Name"
              rules="required"></dg-select-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 220px;margin-right: 10px;">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="14"
              :label_span="10"
              valueProperty="value"
              :options="$store.state['App-User-api'].usersByProfiles.BOOKKEEPER"
              :translateLabel='false'
              :translateLabels="false"
              field_mark="required"
              label="BookKeeper"
              name="account_bookkeeper_user_id"
              placeholder=""
              rules="required"></dg-select-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 200px;margin-right: 10px;">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="15"
              :label_span="7"
              valueProperty="value"
              :options="$store.state['App-User-api'].usersByProfiles.PAYROLL"
              :translateLabel='false'
              :translateLabels="false"
              field_mark="required"
              label="Payroll"
              name="account_payroll_user_id"
              placeholder=""
              rules="required"></dg-select-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 140px;">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="13"
              :label_span="11"
              :options="dropdownPriority"
              :translateLabel='false'
              :translateLabels="false"
              field_mark="required"
              label="Priority"
              name="business_priority_id"
              placeholder="Priority"
              rules="required"></dg-select-field>
        </el-col>
      </el-row>

      <el-row class="mt-1">
        <el-col :class="$langConfig.cssClass" style="width: 250px">
          <business-number :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled" :editMode="editMode"></business-number>
        </el-col>
        <el-col :class="$langConfig.cssClass" style="width: 360px;margin-left: 10px;">
          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="17"
              :label_span="7"
              :translateLabel='false'
              field_mark="required"
              label="Business Name"
              name="business_name"
              placeholder="Name"
              rules="required"></dg-text-field>
        </el-col>
        <el-col :class="$langConfig.cssClass" style="width: 340px;margin-left: 10px;">
          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="18"
              :label_span="6"
              :translateLabel='false'
              field_mark="required"
              label="Legal Name"
              name="business_legal_name"
              placeholder="Legal Name"
              rules="required"></dg-text-field>
        </el-col>
      </el-row>

      <el-row class="mt-1">
        <el-col :class="$langConfig.cssClass" style="width: 210px;">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="14"
              :label_span="10"
              :options="dropdownLegalStructure"
              :translateLabel='false'
              :translateLabels="false"
              field_mark="required"
              label="Legal Entity"
              name="business_legal_structure_id"
              placeholder=""
              rules="required"></dg-select-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 250px;margin-left: 10px;">
          <dg-date-picker-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="12"
              :label_span="12"
              :translateLabel='false'
              field_mark="required"
              format="dd/MM/yyyy"
              label="Established Date"
              name="business_established_date"
              placeholder="dd/mm/yyyy"
              rules="required"
              type="date"
              valueFormat="yyyy-MM-dd"></dg-date-picker-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 210px;margin-left: 10px">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="13"
              :label_span="11"
              :options="$store.state['Business-Type-api'].dropDownList"
              :translateLabel='false'
              :translateLabels="false"
              field_mark="required"
              label="Business Type"
              name="business_type_id"
              placeholder=""
              rules="required"></dg-select-field>
        </el-col>


        <!--<el-col :class="$langConfig.cssClass" style="width: 100px; margin-left: 10px">
          <dg-date-picker-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="24"
              :label_span="24"
              :translateLabel='false'
              field_mark="required"
              format="MM/yyyy"
              label="Client Since"
              name="business_start_date"
              placeholder="month"
              rules="required"
              type="month"
              valueFormat="yyyy-MM-dd"></dg-date-picker-field>
        </el-col>-->

        <el-col :class="$langConfig.cssClass" style="width: 240px;margin-left: 10px">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="9"
              :label_span="15"
              @amUpdated="(p, val) =>  {$emit('amUpdated', {'field' : 'business_province_of_operation_id', val: val})}"
              :options="dropdownProvinces"
              :translateLabel='false'
              :translateLabels="false"
              field_mark="required"
              label="Province of Operation"
              name="business_province_of_operation_id"
              placeholder="Name"
              rules="required"></dg-select-field>
        </el-col>
      </el-row>

      <el-row class="mt-1">
        <el-col :class="$langConfig.cssClass" style="width: 400px;">
          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="19"
              :label_span="5"
              :translateLabel='false'
              field_mark="required"
              label="Head Office"
              name="business_head_office_address"
              placeholder="Address"
              rules="required"></dg-text-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 260px;margin-left: 10px;">
          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="16"
              :label_span="8"
              :translateLabel='false'
              field_mark="optional"
              maxLength="20"
              label="ONe-Key ID"
              name="business_company_one_key_identity"
              placeholder=""
              rules=""></dg-text-field>
        </el-col>


        <el-col :class="$langConfig.cssClass" style="width: 220px; margin-left: 10px;">
          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="16"
              :label_span="8"
              :translateLabel='false'
              field_mark="optional"
              label="Password"
              name="business_company_password"
              placeholder=""
              rules=""></dg-text-field>
        </el-col>
      </el-row>

      <el-row class="mt-1">
        <el-col :class="$langConfig.cssClass" style="width: 250px;" v-if="provinceId == 1 || provinceId == 1000">
          <ontario-company-key editMode="editMode" :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"></ontario-company-key>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 250px;">
          <corporation-canada-key :editMode="editMode" :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"></corporation-canada-key>
        </el-col>
        <el-col :class="$langConfig.cssClass" style="width: 200px;margin-left: 10px;">
          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="19"
              :label_span="5"
              :translateLabel='false'
              field_mark="optional"
              label="ISED"
              name="business_ised"
              placeholder=""
              rules=""></dg-text-field>
        </el-col>
      </el-row>

      <el-row>
        <el-col :class="$langConfig.cssClass" style="width: 200px;">
          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="16"
              :label_span="8"
              :translateLabel='false'
              field_mark="optional"
              label="Contact"
              name="contact_name"
              placeholder="e.g. Mohammad"
              rules=""></dg-text-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 210px; margin-left: 10px">
          <phone-number :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled" :editMode="editMode" :with-extension="false" label="Cellular" phoneAreaPrefixName="contact_cellular_prefix" phoneNumberName="contact_cellular" phoneNumberExtName="contact_cellular_ext"></phone-number>
        </el-col>


        <el-col :class="$langConfig.cssClass" style="width: 260px;margin-left: 10px;">
          <phone-number :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled" :editMode="editMode" :with-extension="true" label="Work #"></phone-number>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 200px;margin-left: 10px;">
          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="18"
              :label_span="6"
              :translateLabel='false'
              field_mark="optional"
              label="Email"
              name="contact_email"
              placeholder="example@email.com"
              rules=""></dg-text-field>
        </el-col>
      </el-row>

      <el-row class="mt-2">
        <el-col :class="$langConfig.cssClass" style="width: 200px;">
          <phone-number labelWidth="40px" :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled" :editMode="editMode" :with-extension="false" label="Fax" phoneAreaPrefixName="contact_fax_area_code" phoneNumberName="contact_fax" phoneNumberExtName="contact_fax_ext"></phone-number>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 70px;">
          <dg-single-checkbox-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="7"
              :label_span="15"
              :labelThenField="true"
              :translateLabel='false'
              :valueFormatter="(val) => { return (val)? 1 : 0}"
              field_mark=""
              @amUpdated="(p, val)=>{hasAudit = val}"
              label="Audit"
              name="audit"
              rules=""></dg-single-checkbox-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 150px" v-if="hasAudit">
          <dg-date-picker-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="15"
              :label_span="9"
              :translateLabel='false'
              field_mark="required"
              format="yyyy"
              label="From"
              name="audit_from"
              placeholder="year"
              rules="required"
              type="year"
              valueFormat="yyyy-MM-dd"></dg-date-picker-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 140px;margin-left: 10px;" v-if="hasAudit">
          <dg-date-picker-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="18"
              :label_span="6"
              :translateLabel='false'
              field_mark="required"
              format="yyyy"
              label="To"
              name="audit_to"
              placeholder="year"
              rules="required"
              type="year"
              valueFormat="yyyy-MM-dd"></dg-date-picker-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 70px; margin-left: 10px;" v-if="hasAudit">
          <dg-single-checkbox-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="7"
              :label_span="15"
              :labelThenField="true"
              :translateLabel='false'
              :valueFormatter="(val) => { return (val)? 1 : 0}"
              field_mark=""
              label="Done"
              name="audit_completed"
              rules=""></dg-single-checkbox-field>
        </el-col>
      </el-row>

      <el-row class="mt-2">
        <el-col :class="$langConfig.cssClass" :span="24">
          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="24"
              :label_span="24"
              :translateLabel='false'
              field_mark=""
              label=""
              prepend="hassan"
              name="business_section_notes"
              placeholder=""
              inputPrepend="Notes"
              rules=""></dg-text-field>
        </el-col>
      </el-row>

    </div>


    <!--<el-row>
      <el-col :class="$langConfig.cssClass" :span="(!editMode)? 11 : 11">
        <dg-text-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :field_span="23"
            :label_span="24"
            :translateLabel='false'
            field_mark="required"
            label="Business Description"
            name="business_description"
            placeholder=""
            rules="required"></dg-text-field>
      </el-col>
    </el-row>-->
  </div>
</template>

<script>
import jsonBusinessTypes from '@/json/dropdown/BusinessTypes.json'
import jsonProvinces from '@/json/dropdown/Provinces.json'
import jsonPriority from '@/json/dropdown/Priority.json'
import jsonLegalStructure from '@/json/dropdown/LegalStucture.json'
export default {
  mounted() {
    if ($xBundle['CURRENT_USER_PROFILE'].profile_id === 'ADMIN') {
      this.control.fields['account_person_in_charge_user_id'].field.setValue($xBundle['CURRENT_USER_PROFILE'].id)
    } else {
      this.control.fields['account_person_in_charge_user_id'].field.setValue(this.control.dataModel.account_person_in_charge_user_id)
    }
  },
  methods: {
    getObjectFromValue(v, type) {
      let op = $store.state['App-User-api'].usersByProfiles[type];
      for (let x in op) {
        if (op[x].value == v) return op[x]
      }
      return null
    },
  },
  components: {
    'business-number': () => import('@/components/business-number/View'),
    'phone-number': () => import('@/components/phone-number/View'),
    'ontario-company-key': () => import('@/components/ontario-company-key/View'),
    'corporation-canada-key': () => import('@/components/corporation-canada-key/View'),
  },
  props: {
    editMode: {
      required: true,
    },
    provinceId: {
      required: true,
    },
    control: {
      required: true
    }
  },
  data () {
    return {
      checkDublicateFieldsTimeout: null,
      hasAudit: false,
      dropdownLegalStructure: jsonLegalStructure,
      dropdownPriority: jsonPriority,
      visibility: true,
      dropdownProvinces: jsonProvinces,
      dropdownBusinessTypes: jsonBusinessTypes
    }
  }
}
</script>
